import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

interface SlideshowProps {
  ratio: string;
  input: { src: string; caption: string }[];
  automatic?: boolean;
  timeout?: number;
  showDots?: boolean;
  showButtons?: boolean;
}

const Slideshow: React.FC<SlideshowProps> = ({
  ratio,
  input,
  automatic,
  timeout = 5000,
  showDots,
  showButtons,
}) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [ratioWidth, ratioHeight] = ratio.split(":").map(parseFloat);
  const ratioWH = ratioWidth / ratioHeight;
  const ratioHW = ratioHeight / ratioWidth;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const imageContainerRef = useRef<HTMLDivElement | null>(null);
  const automaticInterval = useRef<NodeJS.Timeout | null>(null);

  const getNewSlideIndex = (step: number): number => {
    const numberSlide = input.length;
    let newSlideIndex = slideIndex + step;
    if (newSlideIndex >= numberSlide) newSlideIndex = 0;
    else if (newSlideIndex < 0) newSlideIndex = numberSlide - 1;
    return newSlideIndex;
  };

  const backward = (): void => {
    setSlideIndex(getNewSlideIndex(-1));
  };

  const forward = (): void => {
    setSlideIndex(getNewSlideIndex(1));
  };

  const updateDimensions = (): void => {
    if (containerRef.current && imageContainerRef.current) {
      const maxWidth = containerRef.current.clientHeight * ratioWH;
      const maxHeight = containerRef.current.clientWidth * ratioHW;
      imageContainerRef.current.style.maxHeight = `${maxHeight}px`;
      imageContainerRef.current.style.maxWidth = `${maxWidth}px`;
    }
  };

  const runAutomatic = (): void => {
    setSlideIndex(getNewSlideIndex(1));
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    if (automatic) {
      automaticInterval.current = setInterval(runAutomatic, timeout);
    }
    return () => {
      window.removeEventListener("resize", updateDimensions);
      if (automaticInterval.current) clearInterval(automaticInterval.current);
    };
  }, [automatic, timeout]);

  return (
    <Container ref={containerRef}>
      <InnerContainer ref={imageContainerRef}>
        {input.map((image, index) => (
          <ImageContainer
            key={index}
            className={`slide ${slideIndex === index ? "active" : ""}`}
          >
            <Image
              src={`${image.src}_320.png`}
              alt={image.caption}
              srcSet={`
                ${image.src}_320.png 320w,
                ${image.src}_480.png 480w,
                ${image.src}_768.png 768w,
                ${image.src}_1024.png 1024w,
                ${image.src}_1280.png 1280w,
                ${image.src}_1600.png 1600w
              `}
              sizes="(max-width: 820px) 100vw,
              40vw"
              loading="lazy"
            />
          </ImageContainer>
        ))}
        {showButtons && input.length > 1 && (
          <>
            <PrevNext className="prev" onClick={backward}>
              ❮
            </PrevNext>
            <PrevNext className="next" onClick={forward}>
              ❯
            </PrevNext>
          </>
        )}
        {showDots && (
          <DotContainer className="dot-container">
            {input.map((_, dotIndex) => (
              <Dot
                key={dotIndex}
                className={`dot ${slideIndex === dotIndex ? "active" : ""}`}
                onClick={() => setSlideIndex(dotIndex)}
              />
            ))}
          </DotContainer>
        )}
      </InnerContainer>
    </Container>
  );
};

export default Slideshow;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity 0.6s ease;
  &.active {
    opacity: 1;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
`;

const PrevNext = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  font-weight: bold;
  transition: background-color 0.6s ease;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
  &.next {
    right: 3px;
    border-radius: 3px;
  }
  &.prev {
    left: 3px;
    border-radius: 3px;
  }
`;

const DotContainer = styled.div`
  text-align: center;
  margin: 1rem auto;
`;

const Dot = styled.span`
  height: 15px;
  width: 15px;
  margin: 0 0.4rem;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  &.active,
  &:hover {
    background-color: #717171;
  }
  &:not(.active):hover {
    cursor: pointer;
  }
`;
