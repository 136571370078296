import React from "react";
import styled from "styled-components";
import Slideshow from "./slideshow";
import { ElementProps } from "../interfaces";
import Footer from "./Footer";
import { PiLinkSimple } from "react-icons/pi";

const StandardLayoutMinWidth = 820;

const BodyElement1: React.FC<ElementProps> = (props) => {
  const slideshowInput = props.imgSrcs.map((src) => ({ src, caption: "" }));

  return (
    <ElementContainer>
      <ImageContainer>
        <Slideshow input={slideshowInput} ratio="16:9" showButtons={true} />
      </ImageContainer>

      <InfoContainer>
        {/* Texts */}
        <InfoTagContainer>
          {Array.isArray(props.tags) ? (
            props.tags.map((tag, index) => {
              return <InfoTag key={index}>{tag}</InfoTag>;
            })
          ) : (
            <InfoTag>{props.tags}</InfoTag>
          )}
        </InfoTagContainer>
        <InfoTextContainer>
          <InfoTitle>{props.title}</InfoTitle>
          <InfoDescription>{props.description}</InfoDescription>
          {/* Buttons */}
          <InfoURLContainer>
            <PiLinkSimple size={22} style={{ marginRight: "4px" }} />
            {/* Trailer */}
            {props.trailerUrl ? (
              <InfoURL href={props.trailerUrl}>Trailer</InfoURL>
            ) : null}

            {/* Meta Store */}
            {props.metaStoreUrl ? (
              <InfoURL href={props.metaStoreUrl}>Meta</InfoURL>
            ) : null}

            {/* Play Store */}
            {props.playStoreUrl ? (
              <InfoURL href={props.playStoreUrl}>Android</InfoURL>
            ) : null}

            {/* App Store */}
            {props.appStoreUrl ? (
              <InfoURL href={props.appStoreUrl}>iOS</InfoURL>
            ) : null}

            {/* Steam Store */}
            {props.steamStoreUrl ? (
              <InfoURL href={props.steamStoreUrl}>Steam</InfoURL>
            ) : null}
          </InfoURLContainer>
        </InfoTextContainer>
      </InfoContainer>
    </ElementContainer>
  );
};

const BodyElement2: React.FC<ElementProps> = (props) => {
  const slideshowInput = props.imgSrcs.map((src) => ({ src, caption: "" }));
  return (
    <ElementContainer>
      <InfoContainer>
        {/* Texts */}
        <InfoTagContainer>
          {Array.isArray(props.tags) ? (
            props.tags.map((tag, index) => {
              return <InfoTag key={index}>{tag}</InfoTag>;
            })
          ) : (
            <InfoTag>{props.tags}</InfoTag>
          )}
        </InfoTagContainer>
        <InfoTextContainer>
          <InfoTitle>{props.title}</InfoTitle>
          <InfoDescription>{props.description}</InfoDescription>
          {/* Buttons */}
          <InfoURLContainer>
            <PiLinkSimple size={22} style={{ marginRight: "4px" }} />
            {/* Trailer */}
            {props.trailerUrl ? (
              <InfoURL href={props.trailerUrl}>Trailer</InfoURL>
            ) : null}

            {/* Meta Store */}
            {props.metaStoreUrl ? (
              <InfoURL href={props.metaStoreUrl}>Meta</InfoURL>
            ) : null}

            {/* Play Store */}
            {props.playStoreUrl ? (
              <InfoURL href={props.playStoreUrl}>Android</InfoURL>
            ) : null}

            {/* App Store */}
            {props.appStoreUrl ? (
              <InfoURL href={props.appStoreUrl}>iOS</InfoURL>
            ) : null}

            {/* Steam Store */}
            {props.steamStoreUrl ? (
              <InfoURL href={props.steamStoreUrl}>Steam</InfoURL>
            ) : null}
          </InfoURLContainer>
        </InfoTextContainer>
      </InfoContainer>

      <ImageContainer>
        <Slideshow input={slideshowInput} ratio="16:9" showButtons={true} />
      </ImageContainer>
    </ElementContainer>
  );
};

interface BodyProps {
  elements: ElementProps[];
}

const Body: React.FC<BodyProps> = (props) => {
  return (
    <>
      <BodyContainer>
        {props.elements.map((Element, index) =>
          index % 2 === 0 ? (
            <BodyElement1
              key={index}
              title={Element.title}
              tags={Element.tags}
              description={Element.description}
              imgSrcs={Element.imgSrcs}
              trailerUrl={Element.trailerUrl}
              appStoreUrl={Element.appStoreUrl}
              playStoreUrl={Element.playStoreUrl}
              metaStoreUrl={Element.metaStoreUrl}
              steamStoreUrl={Element.steamStoreUrl}
            />
          ) : (
            <BodyElement2
              key={index}
              title={Element.title}
              tags={Element.tags}
              description={Element.description}
              imgSrcs={Element.imgSrcs}
              trailerUrl={Element.trailerUrl}
              appStoreUrl={Element.appStoreUrl}
              playStoreUrl={Element.playStoreUrl}
              metaStoreUrl={Element.metaStoreUrl}
              steamStoreUrl={Element.steamStoreUrl}
            />
          )
        )}
      </BodyContainer>

      <Footer />
    </>
  );
};

const BodyContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;

const ElementContainer = styled.div`
  height: max-content;
  width: 80%;
  display: flex;
  margin-bottom: 85px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${StandardLayoutMinWidth}px) {
    flex-direction: column; /* Layout for smaller screens */
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  width: 47%;
  height: 300px;
  justify-content: center;
  align-items: center;
  min-height: 300px;

  @media (max-width: ${StandardLayoutMinWidth}px) {
    width: 100%;
    margin-bottom: 18px;
    margin-top: 18px;
  }
`;

const InfoContainer = styled.div`
  min-height: 300px;
  display: flex;
  width: 47%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 12px;

  @media (max-width: ${StandardLayoutMinWidth}px) {
    width: 90%;
  }
`;

const InfoTitle = styled.h1`
  margin-top: 10%;
  margin-bottom: 16px;
  text-align: left;
  font-size: 27px;
  font-weight: 600;
`;

const InfoTagContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  top: 0px;
  margin-top: 0;
`;

const InfoTag = styled.div`
  height: 24px;
  border: 1px solid ${({ theme }) => theme.color};
  transition: all 0.5s linear;
  border-radius: 20px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 24px; /* Ensure line height matches the container height */
  white-space: nowrap;
  margin-bottom: 4px;
`;

const InfoDescription = styled.h2`
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.textSecondary};
  transition: color 0.5s linear;
`;

/*
  overflow: hidden;
    text-overflow: ellipsis;
  */

const InfoTextContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
`;

const InfoURLContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: top;
  justify-content: flex-start;
  margin-top: 20px;
  flex-wrap: wrap;
`;

const InfoURL = styled.a`
  margin-left: 12px;
  font-size: 18px;
  color: ${({ theme }) => theme.url};
  transition: all 0.5s linear;
  font-weight: 550;
`;

export default Body;
