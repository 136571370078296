// src/Footer.tsx
import React from "react";
import styled from "styled-components";

const StandardLayoutMinWidth = 820;

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <SocialMediaContainer>
        {/* YouTube */}
        <ExternalLink
          href="https://www.youtube.com/@tfvrgames"
          aria-label="YouTube"
        >
          YouTube
        </ExternalLink>
        <Separator>|</Separator>
        {/* X */}
        <ExternalLink
          href="https://x.com/ForstechStudios"
          aria-label="X/Twitter"
        >
          X/Twitter
        </ExternalLink>
      </SocialMediaContainer>
      <OtherLinksContainer>
        {/* Privacy Policy */}
        <ExternalLink href="/privacy.html" aria-label="Privacy Policy">
          Privacy Policy
        </ExternalLink>
        <Separator>|</Separator>
        {/* Legal Notice */}
        <ExternalLink href="/legalnotice.html" aria-label="Legal Notice">
          Legal Notice
        </ExternalLink>
      </OtherLinksContainer>
    </FooterContainer>
  );
};

const ExternalLink = styled.a`
  transition: all 0.5s linear;
  color: ${({ theme }) => theme.url};
  white-space: nowrap;
`;

const SocialMediaContainer = styled.div`
  display: flex;
  margin-right: 20px;
  @media (max-width: ${StandardLayoutMinWidth}px) {
    margin-right: 4px;
    transform: scale(0.9);
  }
`;

const OtherLinksContainer = styled.div`
  display: flex;
  margin-left: 20px;
  @media (max-width: ${StandardLayoutMinWidth}px) {
    margin-left: 4px;
    transform: scale(0.9);
  }
`;

const FooterContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  background: ${({ theme }) => theme.body};
  transition: background 0.5s linear;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Separator = styled.div`
  margin-left: 8px;
  margin-right: 8px;
`;

export default Footer;
