import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "../components/globalStyles";
import { lightTheme, darkTheme } from "../components/themes";
import { ElementProps } from "../interfaces";
import Header from "../components/Header";
import Body from "../components/Body";
import GameData from "../GameData.json";
import AppData from "../AppData.json";
import MiscData from "../MiscData.json";

const gameElements: ElementProps[] = GameData;
const appElements: ElementProps[] = AppData;
const miscElements: ElementProps[] = MiscData;

const Index = () => {
  const [tabCategory, setTabCategory] = useState<number>(1);
  const [mountedComponent, setMountedComponent] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  //Load theme on mount
  useEffect(() => {
    if (mountedComponent) return;
    const localTheme = window.localStorage.getItem("theme");
    if (localTheme === "dark") setIsDarkMode(true);

    setMountedComponent(true);
  }, [mountedComponent]);

  // Update local storage.
  useEffect(() => {
    let theme = "light";
    if (isDarkMode) theme = "dark";
    window.localStorage.setItem("theme", theme);
  }, [isDarkMode]);

  return (
    <ThemeProvider theme={!isDarkMode ? lightTheme : darkTheme}>
      <GlobalStyles />
      <div className="Home">
        <Header
          setIsDarkMode={setIsDarkMode}
          isDarkMode={isDarkMode}
          tabCategory={tabCategory}
          setTabCategory={setTabCategory}
        />
        <Body
          elements={
            tabCategory === 1
              ? gameElements
              : tabCategory === 2
              ? appElements
              : miscElements
          }
        />
      </div>
    </ThemeProvider>
  );
};

export default Index;
