export const lightTheme = {
  body: "#F8F9FA",
  text: "#212529",
  textSecondary: "#495057",
  url: "#343A40",
  tabBackground: "#CED4DA",
  tabFontColor: "#212529",
};

export const darkTheme = {
  body: "#212529",
  text: "#F8F9FA",
  textSecondary: "#DEE2E6",
  url: "#CED4DA",
  tabBackground: "black",
  tabFontColor: "#F8F9FA",
};
