// src/Header.tsx
import React from "react";
import Tabs from "./tabs";
import styled from "styled-components";
import { darkTheme } from "./themes";
import { DarkModeToggle } from "@anatoliygatt/dark-mode-toggle";

const StandardLayoutMinWidth = 820;

interface HeaderProps {
  setIsDarkMode: (isDarkMode: boolean) => void;
  isDarkMode: boolean;
  setTabCategory: (tabCategory: number) => void;
  tabCategory: number;
}

const Header: React.FC<HeaderProps> = ({
  setIsDarkMode,
  isDarkMode,
  setTabCategory,
  tabCategory,
}) => {
  return (
    <HeaderContainer>
      <NameBox>
        <SignatureText>Tom Förster</SignatureText>
      </NameBox>

      <TabContainer>
        <Tabs
          items={[
            { to: 1, name: "Games" },
            { to: 2, name: "Applications" },
            { to: 3, name: "Misc" },
          ]}
          category={tabCategory}
          setCategory={setTabCategory}
        />
      </TabContainer>
      <SettingsContainer>
        <DarkModeToggle
          onChange={(mode) => {
            mode === "dark" ? setIsDarkMode(true) : setIsDarkMode(false);
          }}
          mode={isDarkMode ? "dark" : "light"}
          dark="Dark"
          light="Light"
          size="sm"
        />
      </SettingsContainer>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 99;
  background: ${({ theme }) => theme.body};
  transition: background 0.5s linear;

  @media (max-width: ${StandardLayoutMinWidth}px) {
    flex-direction: column; /* Layout for smaller screens */
    padding-top: 0px;
    padding-bottom: 8px;
  }
`;

const NameBox = styled.div`
  display: flex;
  left: 70px;
  justify-content: center;
  @media (max-width: ${StandardLayoutMinWidth}px) {
    transform: scale(0.9);
  }
`;

const TabContainer = styled.div`
  display: flex;
  @media (max-width: ${StandardLayoutMinWidth}px) {
    transform: scale(0.9);
  }
`;

const SettingsContainer = styled.div`
  display: flex;
  right: 70px;
  @media (max-width: ${StandardLayoutMinWidth}px) {
    margin-top: 8px;
    transform: scale(0.9);
  }
`;

const SignatureImage = styled.img`
  display: flex;
  width: 90%;

  filter: ${({ theme }) =>
    theme === darkTheme ? "invert(100%)" : "invert(0%)"};
  transition: all 0.5s linear;
`;

const SignatureText = styled.h2`
  transition: all 0.5s linear;
  margin: 8px;
`;

export default Header;
