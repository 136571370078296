import React from "react";
import styled from "styled-components";

const TabsWrapper = styled.div`
  background: ${({ theme }) => theme.tabBackground};
  border: 0px solid black;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 auto;
  position: relative;
  align-items: center;
  display: flex;
  transition: all 0.5s linear;
`;

const TabList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 0;
  height: 60px;
`;

const TabItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  cursor: pointer;
  font-size: 12px;
  position: relative;
  z-index: 2;
  &.is-active {
    z-index: 3;
  }
`;

const ActiveBox = styled.div<{ width: number; offset: number }>`
  height: 68%;
  width: ${(p) => p.width}px;
  transform: translateX(${(p) => p.offset}px) translateY(-50%);
  background: ${({ theme }) => theme.body};
  transition: transform 350ms cubic-bezier(0.15, 0.3, 0.25, 1),
    background 0.5s linear;
  z-index: 1;
  position: absolute;
  top: 50%;
  border: 0px solid black;
  border-radius: 8px;
`;

const TabText = styled.h3`
  color: ${({ theme }) => theme.tabFontColor};
  transition: all 0.5s linear;
`;

const Tabs = (props: {
  items: any[];
  setCategory: (category: number) => void;
  category: number;
}) => {
  const activeRef = React.createRef<HTMLLIElement>();
  const none = React.createRef<HTMLLIElement>();
  const [selected, setSelected] = React.useState(props.category);
  const [offset, setOffset] = React.useState(0);
  const [width, setWidth] = React.useState(0);

  React.useEffect(() => {
    const activeElement = activeRef.current;
    if (activeElement) {
      setOffset(activeElement.offsetLeft);
      setWidth(activeElement.clientWidth);
    }
    props.setCategory(selected);
  }, [selected, activeRef]);

  return (
    <TabsWrapper>
      <TabList>
        <ActiveBox width={width} offset={offset} />
        {props.items.map((item) => {
          return (
            <TabItem
              key={item.to}
              ref={selected === item.to ? activeRef : none}
              className={selected === item.to ? "is-active" : ""}
              onClick={() => setSelected(item.to)}
            >
              <TabText>{item.name}</TabText>
            </TabItem>
          );
        })}
      </TabList>
    </TabsWrapper>
  );
};

export default Tabs;
